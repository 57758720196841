import { useCheckout as _coreFunctionality } from "@shopware-pwa/composables-next";
import { createSharedComposable } from "@vueuse/core";

const _useCheckout = () => {
	const coreFunctionality = _coreFunctionality();

	return {
		...coreFunctionality,
		selectedShippingMethod: coreFunctionality.selectedShippingMethod as ComputedRef<KippieShippingMethod>,
		setShippingMethod: coreFunctionality.setShippingMethod as (method: Partial<KippieShippingMethod>) => Promise<void>,
		shippingMethods: coreFunctionality.shippingMethods as ComputedRef<KippieShippingMethod[]>
	};
};

export const useCheckout = createSharedComposable(_useCheckout);
